import usePost from '@/utils/hooks/api/vercel/usePost';

export default function useLog(module) {
  const { fire, status: requestStatus } = usePost('/activities');

  function log({
    status,
    message,
    feature = '',
    systemCreated = false,
    ...additionalData
  }) {
    fire({
      activityData: {
        module,
        status,
        message,
        feature,
        systemCreated,
        ...additionalData,
      },
    });
  }

  return {
    log,
    requestStatus,
  };
}
