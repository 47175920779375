import moment from 'moment-timezone';

function castToMoment(timestamp, timezone, isDateObject) {
  // Datetime columns in Mui-x-Data-Grid get smashed into local time Date objects, so we
  // have to unwind that back into GMT by removing the timezone data.
  if (isDateObject) {
    timestamp = moment(timestamp).format('YYYY-MM-DDTHH:mm:ss');
  }
  return moment.tz(timestamp, timezone);
}

function format(momentObj, timezone, format) {
  return momentObj.clone().tz(timezone).format(format);
}

// Get the current time as either a string or moment.js object.
export function getCurrentTime(timezone = 'GMT', raw = false) {
  const time = moment().tz(timezone);
  return raw ? time : time.format();
}

// Get the current time plus the offset as either a string or moment.js object.
export function getCurrentTimeOffset(
  timeDifferences,
  timezone = 'GMT',
  raw = false
) {
  const time = moment()
    .tz(timezone)
    .add(timeDifferences?.days || 0, 'days')
    .add(timeDifferences?.hours || 0, 'hours')
    .add(timeDifferences?.minutes || 0, 'minutes');

  return raw ? time : time.format();
}

// Get the format for any supported date type.
export function getFormat(dateType, includeTimezone = true) {
  const formats = {
    date: 'DD MMM YYYY',
    time: 'HH:mm',
    dateTime: 'DD MMM YYYY HH:mm',
    dateTimeWithSeconds: 'DD MMM YYYY HH:mm:ss',
    dateTimeWithMilliSeconds: 'DD MMM YYYY HH:mm:ss.SSS',
  };

  let date = formats[dateType];
  if (includeTimezone) {
    date += ' z';
  }
  return date;
}

// Given a GMT string or Date object, return a formatted date string.
export function getDateString(timestamp, timezone = 'GMT') {
  if (!timestamp) return '';
  const currMoment = castToMoment(timestamp, 'GMT', timestamp instanceof Date);
  return format(currMoment, timezone, getFormat('date', false));
}

// Given a GMT string or Date object, return a formatted time string.
export function getTimeString(timestamp, timezone = 'GMT') {
  if (!timestamp) return '';
  const currMoment = castToMoment(timestamp, 'GMT', timestamp instanceof Date);
  return format(currMoment, timezone, getFormat('time'));
}

// Given a GMT string or Date object, return a formatted date and time string.
export function getDateTimeString(
  timestamp,
  timezone = 'GMT',
  overrideDate = false,
  includeTimezone = true
) {
  if (!timestamp) return '';
  const currMoment = castToMoment(
    timestamp,
    'GMT',
    overrideDate ? false : timestamp instanceof Date
  );
  return format(currMoment, timezone, getFormat('dateTime', includeTimezone));
}

// Given a GMT string or Date object, return a formatted date and time string with seconds.
export function getDateTimeStringWithSeconds(timestamp, timezone = 'GMT') {
  if (!timestamp) return '';
  const currMoment = castToMoment(timestamp, 'GMT', timestamp instanceof Date);
  return format(currMoment, timezone, getFormat('dateTimeWithSeconds'));
}

// Given a GMT string or Date object, return a formatted date and time string with milliseconds.
export function getDateTimeStringWithMilliSeconds(timestamp, timezone = 'GMT') {
  if (!timestamp) return '';
  const currMoment = castToMoment(timestamp, 'GMT', timestamp instanceof Date);
  return format(currMoment, timezone, getFormat('dateTimeWithMilliSeconds'));
}

// Given 2 date strings or Date objects, return the difference in milliseconds.
export function compareTimes(a, b) {
  return castToMoment(b) - castToMoment(a);
}

// Given a date string or Date object, return the date in ISO format. Particularly useful for Dynamo.
export function getISODate(timestamp, timezone = 'GMT') {
  return castToMoment(timestamp, timezone).format('YYYY-MM-DD');
}

// Given a date string or Date object, return the date and time in ISO format. Particularly useful for Dynamo.
export function getISODateTime(timestamp, timezone = 'GMT') {
  return castToMoment(timestamp, timezone).format('YYYY-MM-DDTHH:mm:ss');
}
