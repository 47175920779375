import { ORG_TABLE_NAME } from '@/utils/aws/configs';
import { commandCall } from '@/utils/request';

export default async function getUser(email, orgName) {
  const response = await commandCall('GetCommand', {
    TableName: ORG_TABLE_NAME,
    Key: {
      PK: `ORG:${orgName}`,
      SK: `USER:${email}`,
    },
  });

  return response.Item || {};
}
