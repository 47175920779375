const specials = '!@$%&_+';
const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = lowercase.toUpperCase();

function pickNumber() {
  return Math.floor(Math.random() * 10);
}

function pickSpecial() {
  const randomSpecial = Math.floor(Math.random() * specials.length);
  return specials[randomSpecial];
}

function pickLower() {
  const randomLower = Math.floor(Math.random() * lowercase.length);
  return lowercase[randomLower];
}

function pickUpper() {
  const randomUpper = Math.floor(Math.random() * uppercase.length);
  return uppercase[randomUpper];
}

function generatePassword() {
  let password = '';
  const passwordLength = 12;
  let hasNumber = false;
  let hasSpecial = false;
  let hasUpper = false;

  for (let i = 0; i < passwordLength; i++) {
    const random = Math.floor(Math.random() * 4);
    if (random === 0 && !hasNumber) {
      password += pickNumber();
      hasNumber = true;
    } else if (random === 1 && !hasSpecial) {
      password += pickSpecial();
      hasSpecial = true;
    } else if (random === 2 && !hasUpper) {
      password += pickUpper();
      hasUpper = true;
    } else {
      password += pickLower();
    }
  }

  return password;
}

export default generatePassword;
