import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorBoundary from '@/components/Reusable/ErrorHandling/ErrorBoundary';
import CustomerSupportModal from '@/components/LayoutTools/Header/CustomerSupportModal';

function ErrorComponent({ message, refresh }) {
  const [modalOpen, setModalOpen] = useState(false);

  function handleRefresh() {
    if (typeof refresh === 'function') refresh();
    window.location.reload();
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 600,
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <ErrorOutlineIcon color='error' sx={{ margin: '0 5px' }} />
        {message || 'Unexpected Error'}
      </Typography>
      <Button onClick={handleRefresh}>
        <RefreshIcon color='#A0A0AB' />
      </Button>
      <Typography>
        Please refresh the page or try again later. If the problem persists,
        please contact support.
      </Typography>
      <ErrorBoundary>
        <Button
          onClick={() => setModalOpen(true)}
          variant='contained'
          sx={{ mt: 2 }}
        >
          Contact support
        </Button>
        <CustomerSupportModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </ErrorBoundary>
    </div>
  );
}

export default ErrorComponent;
