import React from 'react';
import { Drawer } from '@mui/material';
import SidebarBase from '../Sidebar/SidebarBase';

export default function MobileSidebar({
  toggleDrawer,
  mobileMenuOpen,
  isMobile,
}) {
  return (
    <Drawer anchor={'left'} open={mobileMenuOpen} onClose={toggleDrawer(false)}>
      <SidebarBase isMobile={isMobile} />
    </Drawer>
  );
}
