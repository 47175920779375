import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackBarContext } from '@/utils/snackbar';

export default function usePost(endpoint, config = {}) {
  const [pinging, setPinging] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [pingResponse, setPingResponse] = useState({});
  const { updateSnack } = useSnackBarContext();

  let fullEndpoint = '/api' + endpoint;

  const mutation = useMutation((data) => {
    setRequestData(data);
    return axios.post(fullEndpoint, data);
  });

  // handle snack for error
  useEffect(() => {
    if (!mutation.isError) return;
    console.error('Error in usePost', mutation.error);
    updateSnack({
      severity: 'error',
      message: mutation.error.message,
    });
  }, [mutation.isError]);

  // If this is a method the requires pinging for updates, invoke whenever we invoke
  // mutation.
  useEffect(() => {
    if (!config.ping) return;
    if (mutation.isLoading) setPinging(true);
    if (pinging && !mutation.isLoading) pingAndWait();
  }, [mutation.isLoading]);

  // Cancel ping if something breaks.
  useEffect(() => setPinging(false), [mutation.error]);

  // Endlessly make the same ping request.
  function pingAndWait() {
    if (!pinging) return;
    axios
      .post(fullEndpoint + '?ping=true', requestData)
      .then((resp) => {
        const { body } = resp.data;
        if (body) resp.data = body;

        // Store ping data.
        setPingResponse(resp.data);

        // If we're not done, ping again.
        if (resp.data?.completed) setPinging(false);
        else setTimeout(() => pingAndWait(requestData), 3000);
      })
      .catch((err) => console.log(err));
  }

  return {
    request: mutation,
    fire: mutation.mutate,
    fireAsync: mutation.mutateAsync,
    isLoading: pinging || mutation.isLoading,
    isError: mutation.isError,
    data: mutation.data?.data?.body || mutation.data?.data,
    error: mutation.error,
    reset: mutation.reset,
    isSuccess: !pinging && mutation.isSuccess,
    status: mutation.status,
    pingAndWait,
    pingResponse,
  };
}
