import React from 'react';
import GeneralUseDialog from '@/components/Reusable/GeneralUse/Dialogs/GeneralUseDialog';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  listIcon: {
    minWidth: '20px',
    fontSize: '8px',
  },
  primaryText: {
    fontWeight: 'bold',
  },
}));

export const PrivacyPolicyText = () => {
  const { classes } = useStyles();
  return (
    <Box>
      <Typography variant='subtitle1' gutterBottom>
        Version: June 2024
      </Typography>

      <Typography variant='h2' gutterBottom>
        Introduction
      </Typography>
      <Typography paragraph>
        This privacy statement describes how Cornerstone Information Systems,
        Inc., an Indiana corporation, and its subsidiaries or partners,
        including ApexConnect (collectively, "Cornerstone," "we," "us," or
        "our") collects and uses personal or other information you provide on
        our websites or services. Cornerstone operates the websites located at{' '}
        <Link href='https://hub.ciswired.com'> https://hub.ciswired.com</Link>,{' '}
        <Link href='https://ciswired.com'> https://ciswired.com</Link>,{' '}
        <Link href='https://www.iqcx.com'> https://www.iqcx.com</Link>,{' '}
        <Link href='https://apexconnect.io'> https://apexconnect.io</Link>, and
        other related sites (collectively, the "Site"), offering services
        information, industry content, and other various software offerings
        (together with the Site, the "Services").
      </Typography>
      <Typography paragraph>
        We respect and protect the privacy of our users. We maintain strict
        policies to ensure the privacy of those who use our Services ("End
        Users," "you," or "your") or those who may just visit our Site without
        otherwise using our Services ("Visitors"). This policy ("Privacy
        Policy") describes the types of information we may collect from you and
        our practices for collecting, using, maintaining, protecting, and
        disclosing such information. This Privacy Policy also includes a
        description of certain rights that you may have over information that we
        may collect from you.
      </Typography>
      <Typography paragraph>
        The use of information collected through our Sites or Service shall be
        limited to the purpose of supporting those Sites or Services, including
        the service for which the Client has engaged Cornerstone or a
        Cornerstone subsidiary or partner. We do not sell data.
      </Typography>
      <Typography paragraph>
        By using the Services, you agree to this Privacy Policy. If you do not
        agree with our policies and practices, your choice is to not use our
        Services.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Summary of Data Collection, Disclosure and Sale
      </Typography>
      <Typography paragraph>
        Here is a short summary of the categories of data we have collected and
        disclosed over the last twelve months. We do not sell data. The rest of
        this Privacy Policy provides more in-depth information on our privacy
        practices.
      </Typography>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Identifiers, such as contact details, including real name, alias,
        address, telephone number, unique personal identifiers, online
        identifiers, IP address, email address, and account name.
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Categories of PI described in the California Consumer Privacy Act, GDPR
        or otherwise (including name, email, phone number, mailing address,
        birthday, SSN, driver's license number, bank account number, health
        information)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Commercial information: (Transaction information, purchase history,
        financial details, and payment information)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Geolocation data: (device location)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Internet or other electronic network activity information: (Browsing
        history, search history, online behavior, interest data, and
        interactions with our and other websites, applications, systems, and
        advertisements)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Inferences drawn from other personal information to create a profile
        about a consumer: (for example, an individual's preferences and
        characteristics)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we disclose this data as part of our business? Yes' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Will we sell this data? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Biometric information
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Characteristics of protected classifications under California or United
        States federal law
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Audio, visual or similar information
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Professional or employment related information
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? Yes' />
        </ListItem>
      </List>

      <Typography variant='body1' gutterBottom>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Non-public education information (per the Family Educational Rights and
        Privacy Act)
      </Typography>
      <List disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Do we collect? No' />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        Types of Information We Collect
      </Typography>

      <Typography variant='h4' gutterBottom>
        1. Personal Data
      </Typography>
      <Typography paragraph>
        "Personal Data" or Personally Identifiable Information ("PII") is
        information by which you may be uniquely, personally identified. In
        addition, we may collect and store, on behalf of our clients, some
        system administration contact information. Typically, this involves our
        client's contact information, such as email addresses, to notify users
        of service notifications. Cornerstone may collect the following Personal
        Data from you:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Name' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Email Address' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Postal Address' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Phone Number' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Travel Data' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Company of employment name and information' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Any other information voluntarily supplied by you to us reasonably necessary to perform the Services (optional).' />
        </ListItem>
      </List>
      <Typography paragraph>
        Your bank account information, payment information, social security
        number, or driver's license number may be collected by third-party
        vendors, including our payment processors. Such identifying information
        is not collected or stored by Cornerstone. Your data may be processed,
        collected, shared or stored by third-party vendors, including website
        service providers, website analytics providers, third party payment
        processors, or other service providers we use to conduct business. Such
        processing, collection, sharing or storing of data by such third parties
        is not processed, collected, shared or stored by Cornerstone, with the
        exception of our analytics service provider as we use such services to
        assist in better understanding the Visitors on our Site.
      </Typography>

      <Typography variant='h4' gutterBottom>
        2. Non-Personal Data
      </Typography>
      <Typography paragraph>
        Non-Personal Data includes any data that cannot be used on its own to
        identify, trace, or identify a person. We may collect your device
        information, including GPS data and IP address.
      </Typography>
      <Typography paragraph>
        We may collect the following Non-Personal Data:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Device Information'
            secondary='Information about the devices you use to access our services, such as hardware models, operating system and version, and mobile network information.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Usage Data'
            secondary='Information about your interactions with our services, including the content you view, the dates and times of your visits, the amount of time you spend on our services, and other interaction data.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Location Data'
            secondary="Information about your location, which can be derived from your device's GPS, Bluetooth, or Wi-Fi signals. This data helps us to tailor the services based on your geographic location."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Browser and Log Data'
            secondary='Information about your web browser, including the type of browser, language preference, referring site, and the date and time of each visitor request.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Aggregated Data'
            secondary='Data that has been aggregated in a manner that does not reveal any specific individual user. This may include statistical data about user activity (e.g., the number of users who have viewed a particular page or used a particular feature).'
          />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        How We Collect Information
      </Typography>
      <Typography paragraph>
        We may collect personal information about you from various sources,
        including:
      </Typography>
      <Typography paragraph sx={{ mb: 0 }}>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Information you provide directly to us when you use our Services or
        otherwise interact with us; for example:
      </Typography>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you apply or register for the service' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you fill out contact or registration forms' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you fill out feedback forms' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you contact us through our Site' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleOutlinedIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='When you contact us for service requests' />
        </ListItem>
      </List>
      <Typography paragraph>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Information we collect automatically through your use of our Services
      </Typography>
      <Typography paragraph>
        <ListItemIcon className={classes.listIcon}>
          <CircleIcon fontSize='string' />
        </ListItemIcon>{' '}
        Information we obtain from third-party sources.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Information Collected from Third Parties
      </Typography>
      <Typography paragraph>
        <b>Information from our service providers:</b> We may receive
        information about you from third- party service providers, including
        from those we engage to market our products and services.
      </Typography>
      <Typography paragraph>
        <b>
          Information from social media sites and other publicly available
          sources:
        </b>{' '}
        When you choose to login or sync your account through a third-party
        account, or interact or engage with us on social media sites, or other
        social platforms, through posts, comments, questions, instant messaging,
        and other interactions, we may collect such publicly accessible
        information to allow us to connect with you, improve our products, or
        better understand user reactions and issues. Once collected, this
        information may remain with us even if you delete it from the social
        media sites. We may also add and update information about you, from
        other publicly available sources.
      </Typography>
      <Typography paragraph>
        When non-Personal Data you give to us is combined with Personal Data we
        collect about you, it will be treated as Personal Data and we will only
        use it in accordance with this Privacy Policy.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Passive and Automated Collection Methods
      </Typography>
      <Typography paragraph>
        As is true of most websites, we gather certain information
        automatically. This information may include Internet protocol (IP)
        addresses, browser type, Internet service provider (ISP), referring/exit
        pages, the files viewed on our site (e.g., HTML pages, graphics, etc.),
        operating system, date/time stamp, and/or clickstream data to analyze
        trends in the aggregate and administer the site.
      </Typography>
      <Typography paragraph>
        We and our service providers may use various technologies to collect
        information, including cookies, web beacons, and log files.
      </Typography>
      <Typography paragraph>
        <b>Cookies.</b> A cookie is a small file placed on the hard drive of
        your computer. We use cookies to analyze trends, track users movements
        around the website, help manage and administer our website and report on
        your interaction with the Site, and gather demographic information as a
        whole. We use both first-party and third-party cookies for these
        purposes. You can control the use of cookies at the individual browser
        level, but if you choose to disable cookies, it may limit your use of
        certain features or functions on our Services. You can control the use
        of cookies at the individual browser level, but if you choose to disable
        cookies, it may limit your use of certain features or functions on our
        Site or Services.
      </Typography>
      <Typography paragraph>
        <b>Web Beacons.</b> Web beacons are electronic images that may be used
        in our Services or emails to help deliver cookies, count visits, and
        understand usage and campaign effectiveness.
      </Typography>
      <Typography paragraph>
        <b>Log Files.</b> We use means through the Services to collect IP
        addresses, location data, and access times.
      </Typography>
      <Typography paragraph>
        We may partner with a third party to display advertising on our website
        or to manage our advertising on other sites. Our third-party partner may
        use cookies or similar technologies to provide you advertising based
        upon your browsing activities and interests. If you wish to opt out of
        interest-based advertising click{' '}
        <Link href='https://preferences-mgr.truste.com/'>here</Link> [or if
        located in the European Union click{' '}
        <Link href='https://www.youronlinechoices.eu/'>here</Link>]. Please note
        you will continue to receive generic ads.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Why We Collect and How We Use Your Information
      </Typography>
      <Typography paragraph>
        We collect and use your Personal Data when we have a legitimate purpose
        to do so, including the following reasons:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='to verify your eligibility to use the Services;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='when it is necessary for the general functioning of the Services, including to contact you;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='when it is necessary in connection with any contract you have entered into with us or to take steps prior to entering into a contract with us;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary="when we have obtained your or a third party's prior consent;" />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='when we have a legitimate interest in processing your information for the purpose of providing or improving our Services;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='when we have a legitimate interest in using the information for the purpose of contacting you, subject to compliance with Applicable Law; or' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='when we have a legitimate interest in using the information for the purpose of detecting, and protecting against, breaches of our policies and applicable laws.' />
        </ListItem>
      </List>
      <Typography paragraph>
        We may use aggregated (anonymized) information about our End Users, and
        information that does not identify any individual, without restriction.
      </Typography>
      <Typography paragraph>We may use Non-Personal Data to:</Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Enhance the performance and functionality of our services.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Analyze usage trends and preferences to improve our services.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Monitor and analyze the effectiveness of our marketing efforts.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Customize the user experience and provide content and features that are of interest to you.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Maintain the security and integrity of our services.' />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        Accessing and Controlling Your Information
      </Typography>
      <Typography paragraph>
        We acknowledge that you have the right to access your personal
        information. We have no direct relationship with the individuals whose
        personal data it processes. Upon request, we will provide you with
        information about whether we hold, or process on behalf of a third
        party, any of your personal information.
      </Typography>
      <Typography paragraph>
        If you would like to prevent us from collecting your information
        completely, you should cease use of our Services. You can also control
        certain data via these other methods:
      </Typography>
      <Typography paragraph>
        <b>Correction capabilities:</b> You have the ability to access and
        correct any inaccuracies in your personally identifiable information by
        contacting us at{' '}
        <Link href='mailto:contact@ciswired.com'>contact@ciswired.com</Link>.
      </Typography>
      <Typography paragraph>
        If requested to remove data, we will respond within a reasonable
        timeframe. If you are a customer of one of our Clients and would no
        longer like to be contacted by one of our Clients that use our service,
        please contact the Client that you interact with directly. We handle
        client data as a data processor. Consequently, access requirements are
        handled by them, though we will cooperate with any requests made by our
        data controller clients having to do with their end users. The data
        controller clients can expect a response to their request with 10-20
        business days.
      </Typography>
      <Typography paragraph>
        <b>Opt-out of non-essential electronic communications:</b> You may opt
        out of receiving newsletters and other non-essential messages by using
        the 'unsubscribe' function included in all such messages or by
        contacting us directly at{' '}
        <Link href='mailto:contact@ciswired.com'>contact@ciswired.com</Link>.
        However, you will continue to receive notices and essential
        transactional emails.
      </Typography>
      <Typography paragraph>
        <b>Optional information:</b> You can always choose not to fill in
        non-mandatory fields when you submit any form linked to our services.
      </Typography>
      <Typography paragraph>
        We handle client data as a data processor. Consequently, choice
        requirements are handled by the administrator assigned by the client. If
        you wish to stop receiving site and service announcements or trip
        notifications, please contact your system administrator.
      </Typography>

      <Typography variant='h2' gutterBottom>
        United States Consumer Privacy Acts
      </Typography>
      <Typography paragraph>
        Under the United States California Consumer Privacy Act, the Virginia
        Consumer Data Protection Act, Nevada's privacy laws, and other
        state-specific consumer privacy laws regarding notice of information
        collected, residents of these states have statutory data rights. We
        provide the same control and rights over your data no matter where you
        choose to live in the United States. As a user of Cornerstone, you have
        the following control over your data:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to access'
            secondary="You have the right to access (and obtain a copy of, if required) the categories of personal information that we hold about you, including the information's source, purpose and period of processing, and the persons to whom the information is shared."
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to rectification'
            secondary='You have the right to update the information we hold about you or to rectify any inaccuracies. Based on the purpose for which we use your information, you can instruct us to add supplemental information about you in our database.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to erasure'
            secondary='You have the right to request that we delete your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was originally collected.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to restriction of processing'
            secondary='You may also have the right to request to restrict the use of your information in certain circumstances, such as when you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to data portability'
            secondary='You have the right to transfer your information to a third party in a structured, commonly used and machine-readable format, in circumstances where the information is processed with your consent or by automated means.'
          />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primaryText }}
            primary='Right to object'
            secondary='You have the right to object to the use of your information in certain circumstances, such as the use of your personal information for direct marketing.'
          />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        European Union Legal Basis for Processing (GDPR)
      </Typography>
      <Typography paragraph>
        If you are a resident of the European Economic Area (EEA), you may have
        certain rights under the European Union's General Data Protection
        Regulation (GDPR). The information collected from you by us must be
        explained as follows in order for GDPR compliance. Please email us if
        you have any questions or want to exercise your rights in accordance
        with this Privacy Policy under these applicable laws.
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary="Cornerstone's legal basis for processing your data includes the necessity to contract with you and perform our Services." />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary="Cornerstone's legitimate interests in collecting such data include legitimate interests of our business, including but not limited to performing Services for you and other End Users, collecting necessary information in order to safely and accurately perform such Services, fraud prevention, information security, direct marketing, and legal compliance." />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Cornerstone does not collect categories of personal data from sources other than yourself.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Cornerstone may transfer personal data outside of the jurisdiction, such as in the United States, where we conduct our Services.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Cornerstone stores this information for as long as necessary to conduct Services. We will delete any data that has not been used for a period of five (5) years or longer.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Cornerstone does not use automated decision-making, such as profiling, during your application process.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='If you are a citizen where which GDPR applies, you have the right to object to or restrict data processing, the right to erasure also known as the right to be forgotten, and the right to data portability.' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='At any time, you have the right to withdraw your consent for us to use, store, or collect your data and file a complaint with your local supervisory authority.' />
        </ListItem>
      </List>

      <Typography variant='h2' gutterBottom>
        EU-U.S. Data Privacy Framework
      </Typography>
      <Typography paragraph>
        Cornerstone complies with the EU-U.S. Data Privacy Framework (EU-U.S.
        DPF) and the Swiss- U.S. Data Privacy Framework (Swiss-U.S. DPF) as set
        forth by the U.S. Department of Commerce regarding the collection, use,
        and retention of personal information transferred from the European
        Union, United Kingdom, and Switzerland to the United States,
        respectively. We have certified to the Department of Commerce that we
        adhere to the Data Privacy Framework Principles. If there is any
        conflict between the terms in this privacy policy and the Data Privacy
        Framework Principles, the Data Privacy Framework Principles shall
        govern. To learn more about the Data Privacy Framework program, and to
        view our certification, please visit{' '}
        <Link href='https://www.dataprivacyframework.gov'>
          https://www.dataprivacyframework.gov
        </Link>
      </Typography>
      <Typography paragraph>
        Cornerstone is responsible for the processing of personal data it
        receives under the EU-U.S. DPF and Swiss-U.S. DPF and subsequently
        transfers to a third party acting as an agent on its behalf. Cornerstone
        complies with the EU-U.S. DPF Principles and Swiss-U.S. DPF Principles
        for all onward transfers of personal data from the EU, UK, and
        Switzerland, including the onward transfer liability provisions.
      </Typography>
      <Typography paragraph>
        In compliance with the EU-U.S. DPF and Swiss-U.S. DPF, Cornerstone
        commits to refer unresolved complaints concerning our handling of
        personal data received in reliance on the EU- U.S. DPF and Swiss-U.S.
        DPF to TRUSTe, an alternative dispute resolution provider based in the
        United States. These dispute resolution services are provided at no cost
        to you.
      </Typography>
      <Typography paragraph>
        For complaints regarding EU-U.S. DPF, the UK Extension to the EU-U.S
        DPF, and Swiss-U.S. DPF compliance not resolved by any of the other DPF
        mechanisms, you have the possibility, under certain conditions, to
        invoke binding arbitration. Further information can be found on the
        official DPF website:{' '}
        <Link href='https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2'>
          https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2
        </Link>
      </Typography>

      <Typography variant='h2' gutterBottom>
        Exercise Your Data Rights
      </Typography>
      <Typography paragraph>
        We acknowledge your right to request access, amendment, or deletion of
        your data. We also recognize that you have the right to prohibit sale of
        your data, but we do not sell data.
      </Typography>
      <Typography paragraph>
        You can exercise the rights described above by sending an email or mail
        to the addresses listed in the Questions and Comments section below.
        Only you, or an agent authorized to make a request on your behalf, may
        make a request related to your personal information.
      </Typography>
      <Typography paragraph>
        We cannot respond to your request if, (i) we cannot verify your
        identity; or (ii) your request lacks sufficient details to help us
        handle the request. We will make best efforts to respond to your request
        within forty-five (45) days of its receipt. If we cannot respond in
        forty-five (45) days, we will inform you, in writing, the reason for the
        delay and will respond to your request within 90 days. Any information
        we provide will only cover the twelve (12)-month period preceding the
        request's receipt.
      </Typography>
      <Typography paragraph>
        We do not charge a fee to process or respond to your request unless it
        is excessive, repetitive, or manifestly unfounded. If we determine that
        the request warrants a fee, we will tell you why we made that decision
        and provide you with a cost estimate before completing your request. We
        are not obligated to provide responses to your data requests more than
        twice in a twelve (12)-month period.
      </Typography>

      <Typography variant='h2' gutterBottom>
        How Long Do We Store Personal Data?
      </Typography>
      <Typography paragraph>
        We will retain your information and information we process on behalf of
        our Clients for as long as your account is active or as needed to
        provide you services or to our Clients. We will retain and use your
        information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements. We will only retain your Personal
        Data for as long as is necessary to fulfill the purposes for which it is
        collected. This length of time may vary according to the nature of your
        relationship with us. Personal Data will be purged after five (5) years
        of nonuse of an account.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Users Under the Age of 13
      </Typography>
      <Typography paragraph>
        Our Services are not intended for anyone under 18, particularly children
        under 13 years of age, and we do not knowingly collect Personal Data
        from children under 18. Participation in our Services is limited to
        individuals who are 18 years old or older (or the age of majority in
        your jurisdiction). If you are under 18, do not use or register on the
        Services, use any of the interactive submission features on our Site, or
        provide any information about yourself to us. If we learn we have
        collected or received Personal Data from a child under 18 without
        verification of parental consent, we will delete that information. If
        you believe we might have any information from or about a child under
        18, please contact us at the email address listed below.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Do Not Track Settings
      </Typography>
      <Typography paragraph>
        We do not track our Users over time and across third party websites to
        provide targeted advertising and do not specifically respond to Do Not
        Track ("DNT") signals.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Who We Share Data With
      </Typography>
      <Typography paragraph>
        We may use aggregated (anonymized) information about our End Users and
        Visitors, and information that does not identify any individual, without
        restriction.
      </Typography>
      <Typography paragraph>
        We do not sell Personal Data, specific personal or transactional
        information to anyone except as described below.
      </Typography>

      <Typography variant='h4' gutterBottom>
        Affiliates And Subsidiaries
      </Typography>
      <Typography paragraph>
        We may, for our legitimate interests, share your information with
        entities under common ownership or control with us who will process your
        information in a manner consistent with this Privacy Policy and subject
        to appropriate safeguards. Such parent companies, affiliates, or
        subsidiaries may be located in the United States.
      </Typography>
      <Typography paragraph>
        We may transfer personal information to companies that help us provide
        our service. Transfers to subsequent third parties are covered by the
        service agreements with our Clients.
      </Typography>

      <Typography variant='h4' gutterBottom>
        Successors In Interest
      </Typography>
      <Typography paragraph>
        We may, for our legitimate interests, share your information with a
        buyer or other successor in the event of a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of our assets, in which Personal Data about our End Users is
        among the assets transferred. You will be notified of any such change by
        a prominent notice displayed on our Services or by e-mail. Any successor
        in interest to this Privacy Policy will be bound to the Privacy Policy
        at the time of transfer.
      </Typography>

      <Typography variant='h4' gutterBottom>
        Law Enforcement And Other Governmental Agencies
      </Typography>
      <Typography paragraph>
        We may share your information when we believe in good faith that such
        sharing is reasonably necessary to investigate, prevent, or take action
        regarding possible illegal activities or to comply with legal process.
        This may involve the sharing of your information with law enforcement,
        government agencies, courts, and other organizations.
      </Typography>

      <Typography variant='h4' gutterBottom>
        Service Providers
      </Typography>
      <Typography paragraph>
        We may, for our legitimate interests, share certain information with
        contractors, service providers, third party authenticators, and other
        third parties we use to support our business and who are bound by
        contractual obligations to keep Personal Data confidential and use it
        only for the purposes for which we disclose it to them. Some of the
        functions that our service providers provide are as follows:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Server infrastructure and storage;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Business analytics services;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='User and identity verification management;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Payment processing;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Site log analytics service for activity, performance, and troubleshooting;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Marketing, sales, and service management;' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Conducting our Services; and' />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listIcon}>
            <CircleIcon fontSize='string' />
          </ListItemIcon>
          <ListItemText primary='Email management services.' />
        </ListItem>
      </List>
      <Typography paragraph>
        We may use and disclose your personal data that you have voluntarily
        provided to us to third parties who contract with us to conduct our
        Services. We hereby acknowledge that any such disclosure shall be in
        compliance with all applicable data privacy laws in any given disclosure
        ("Applicable Law(s)") and further acknowledge compliance with any such
        policies of covered third party entities we may work with.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Third-Party Services and Website Links
      </Typography>
      <Typography paragraph>
        Our web sites or services may contain links to other sites. Cornerstone
        is not responsible for the privacy policies or other practices employed
        by websites linked to, or from, our Site nor the information or content
        contained therein. We encourage you to be aware when you leave our Sites
        or Services and to read the privacy statements of any linked website or
        other third party. This includes sharing information via social media
        websites. This privacy statement applies solely to information collected
        by Cornerstone Information Systems companies web sites.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Data Storage and How Cornerstone Protects Your Information
      </Typography>
      <Typography paragraph>
        Cornerstone stores basic End User data on our servers including name,
        email, username, and company name. Payments are not always required by
        End Users. If an End User makes a purchase and a payment is required,
        then payment information is processed and stored by our partners or
        service providers. Personal Data about End Users and Visitors is stored
        within the United States. The Services are only intended to be used
        inside the United States; we do not seek users from outside this
        location at this time. If you are using the Services from the EEA or
        other regions with laws governing data collection and use, please note
        that you are agreeing to the transfer of your Personal Data to the
        United States. Such locations may have laws which are different, and
        potentially not as protective, as the laws of your own country. By
        providing your Personal Data, you consent to any transfer and processing
        in accordance with this Privacy Policy. For the avoidance of doubt, our
        Site and Services are not intended for any users outside of the United
        States.
      </Typography>
      <Typography paragraph>
        Cornerstone employs physical, electronic, and managerial control
        procedures to safeguard and help prevent unauthorized access to your
        information. We choose these safeguards based on the sensitivity of the
        information that we collect, process and store and the current state of
        technology. Our outsourced service providers who support our operations
        are also vetted to ensure that they too have the appropriate
        organizational and technical measures in place to protect your
        information.
      </Typography>
      <Typography paragraph>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we do our best to protect your Personal
        Data, we cannot guarantee the security of your information transmitted
        to the Services. Any transmission of information is at your own risk. We
        are not responsible for circumvention of any privacy settings or
        security measures contained on the Services. In the event that there is
        breach in the information that we hold; we shall notify of such breach
        via email or via notice on the Services.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Security
      </Typography>
      <Typography paragraph>
        This website takes every precaution to protect our users' information.
        When our customers handle sensitive information via the website,
        information is protected both online and off-line. All online data
        transmitted is secured via TLS 1.2 encryption and all data at rest is
        protected by AES 256bit encryption. All our users' information, not just
        the sensitive information mentioned above, is restricted in our offices.
        Only employees who need the information to perform a specific job are
        granted access to personally identifiable information (PII).
      </Typography>
      <Typography paragraph>
        Our employees must use password-protected screensavers when they leave
        their desk. When they return, they must re-enter their password to
        re-gain access to your information. Furthermore, ALL employees are kept
        current on our security and privacy practices. Every calendar quarter,
        as well as any time new policies are added, our employees are notified
        and/or reminded about the importance we place on privacy, and what they
        can do to ensure our customers' information is protected.
      </Typography>
      <Typography paragraph>
        Finally, the servers that may store personally identifiable information
        (PII) on are kept in a secure environment and located in rooms that
        require security card access readers and cameras.
      </Typography>
      <Typography paragraph>
        No method of transmission over the Internet, or method of electronic
        storage, is 100% secure, however. Therefore, we cannot guarantee its
        absolute security. If you have any questions about security on any
        Cornerstone Information Systems companies Web site, you can contact us
        at <Link href='mailto:ask@ciswired.com'>ask@ciswired.com</Link>.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Supplementation of Information
      </Typography>
      <Typography paragraph>
        For this website to properly fulfill its obligation to our clients, it
        is necessary for us to supplement the information we receive with
        information from third party sources. As indicated above we may collect
        some information from industry databases. This information is tied to an
        identifier that our clients use to run reports and analyze the data. It
        is possible for our clients to then also be able to append more of their
        own data to it.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Site and Service Updates
      </Typography>
      <Typography paragraph>
        From time-to-time, we send clients site and service announcement
        updates. These announcements contain important information about the
        service, therefore are required as part of the service. Generally, you
        may not opt-out of these required communications as they are necessary
        to receive the service. If you wish to stop receiving these
        communications, please see the "Choice/Opt-out" section below for
        instruction. We communicate with the user to provide requested services
        and with regards to issues relating to their account via email or phone.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Notification of Changes
      </Typography>
      <Typography paragraph>
        If we decide to change our privacy policy, we will post those changes on
        our corporate Web page so our users are always aware of what information
        we collect, how we use it, and under circumstances, if any, we disclose
        it. If at any point we decide to use personally identifiable information
        (PII) in a manner materially different from that stated at the time it
        was collected, we will notify users by way of an email or prominent
        posting on the website. Users will have a choice as to whether or not we
        use their information in this different manner. We will use information
        in accordance with the privacy policy under which the information was
        collected.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Changes to the Privacy Policy
      </Typography>
      <Typography paragraph>
        It is our policy to post any changes we make to our Privacy Policy on
        this page of the Site. If we make material changes to how we treat our
        End Users' or Visitors' Personal Data, we will notify you by email to
        the primary email address specified in your account or through a
        prominent notice on the Site. Such changes will be effective when
        posted. The date the Privacy Policy was last revised is identified at
        the top of the page. Your continued use of our Services following the
        posting of any modification to this Privacy Policy shall constitute your
        acceptance of the amendments to this Privacy Policy. You can choose to
        delete the Site and discontinue of use the Service if you do not accept
        any modified version of this Privacy Policy.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Questions and Comments
      </Typography>
      <Typography paragraph>
        If you have any questions or comments about this Privacy Policy, or if
        you would like to file a request about the data we hold or file a
        deletion request, please contact Customer Support at{' '}
        <Link href='mailto:contact@ciswired.com'>contact@ciswired.com</Link>.
        You may also contact Cornerstone by telephone (812-330-4361) or postal
        mail:
      </Typography>
      <Typography paragraph>
        Cornerstone Information Systems
        <br />
        304 W. Kirkwood Avenue, Suite 101
        <br />
        Bloomington, IN 47404
      </Typography>
      <Typography paragraph>
        Our Data Protection Officer can be reached at{' '}
        <Link href='mailto:support@ciswired.com'>support@ciswired.com</Link>
      </Typography>
    </Box>
  );
};

export const privacyPolicyTitle = 'PRIVACY POLICY';

function PrivacyPolicyDialog({ open, setOpen }) {
  return (
    <GeneralUseDialog
      open={open}
      setOpen={setOpen}
      contentTitle={privacyPolicyTitle}
      contentText={<PrivacyPolicyText />}
    />
  );
}

export default PrivacyPolicyDialog;
