import { useCallback, useEffect, useState } from 'react';

function useUnsavedChanges(values) {
  const stringifiedValues = JSON.stringify(values);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [savedValues, setSavedValues] = useState(stringifiedValues);

  const setSaved = useCallback(
    (...args) => {
      /*
            lets the saved values that are used for comparison be updated either all at once, or individually

            ex:
                setSaved({foo: 'bar', hello: 'world'}) //with one argument, the entire saved object is updated to this new object
            or
                setSaved(foo, 'bar') //with two arguments, the first is the saved value key and the second is the new value
        */

      let newValues = args?.[0];
      if (args.length > 1) {
        newValues = JSON.parse(savedValues);
        newValues[args?.[0]] = args?.[1];
      }
      setSavedValues(JSON.stringify(newValues));
    },
    [savedValues]
  );

  useEffect(() => {
    setHasUnsavedChanges(stringifiedValues !== savedValues);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, savedValues]);

  return {
    hasUnsavedChanges,
    setSaved,
    savedValues,
  };
}

export default useUnsavedChanges;
