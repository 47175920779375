import { AppBar } from '@mui/material';
import React from 'react';
import HeaderContent from './HeaderContent';

export const HEADER_HEIGHT = 64;

export const Header = ({ isMobile, toggleMobileMenu }) => {
  return (
    <AppBar
      position='sticky'
      elevation={0}
      sx={{
        height: HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #E0E0E0',
        zIndex: 'drawer',
      }}
      color='white'
    >
      <HeaderContent isMobile={isMobile} toggleMobileMenu={toggleMobileMenu} />
    </AppBar>
  );
};
