import React from 'react';
import ErrorComponent from './ErrorComponent';
import ErrorScreen from './ErrorScreen';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  refreshHandler = () => {
    this.setState({ hasError: false });
  };

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.type && this.props.type === 'page') {
        return (
          <ErrorScreen
            title='Something went wrong!'
            code={false}
            message='An unexpected error occurred. We are sorry for any inconvenience.'
            goBack={false}
            refresh={this.refreshHandler}
          />
        );
      }
      return <ErrorComponent refresh={this.refreshHandler} />;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
