import { ORG_TABLE_NAME } from '@/utils/aws/configs';
import { ORG_INDEX_NAME } from '@/utils/constants';
import { commandCall } from '@/utils/request';
import { validateAndFixPermissionsConfig } from '@/utils/hubPermissions/hubPermissionsConfig';

export async function getOrg(orgName) {
  const { Item: org } = await commandCall('GetCommand', {
    TableName: ORG_TABLE_NAME,
    Key: {
      PK: `META`,
      SK: `ORG:${orgName}`,
    },
  });

  if (!org) {
    return {};
  }

  const { config, changed } = validateAndFixPermissionsConfig(org.config);
  org.config = config;

  if (changed) {
    await updateOrg(orgName, org);
  }

  return org || {};
}

export async function updateOrg(orgName, org) {
  await commandCall('PutCommand', {
    TableName: ORG_TABLE_NAME,
    Item: {
      ...org,
      PK: `META`,
      SK: `ORG:${orgName}`,
    },
  });
}

export async function getOrganizationFromUserEmail(email) {
  // Get org based on email using GSI.
  const result = await commandCall('QueryCommand', {
    TableName: ORG_TABLE_NAME,
    IndexName: ORG_INDEX_NAME,
    KeyConditionExpression: 'SK = :SK',
    ExpressionAttributeValues: {
      ':SK': `USER:${email}`,
    },
  });

  return result?.Items?.[0]?.PK?.replace('ORG:', '') || '';
}
