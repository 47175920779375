import HelpSharp from '@mui/icons-material/HelpSharp';
import { IconButton } from '@mui/material';
import React from 'react';

export default function HelpButton({ onClick }) {
  return (
    <IconButton
      disableRipple
      onClick={() => onClick()}
      color='primary'
      aria-label='help'
    >
      <HelpSharp />
    </IconButton>
  );
}
