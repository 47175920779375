import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Image from 'next/image';
import { useSession } from '@/utils/hooks';
import { NEXT_PUBLIC_ORG_LOGOS_S3_BUCKET } from '@/utils/constants';
import CornerstoneLogo from '@/assets/logos/CornerstoneLogo';
import { HEADER_HEIGHT } from '@/components/LayoutTools/Header';

const useStyles = makeStyles()(() => ({
  logoWrapper: {
    margin: '8px',
    height: HEADER_HEIGHT - 16,
  },
}));

function Logo() {
  const { classes } = useStyles();
  const { org } = useSession();
  const oldDefaultLogo =
    process.env.NEXT_PUBLIC_STATIC_ASSETS_URL + 'cis-logo-color.png';
  // this is set in HubConfiguration.js - we don't use this image to render
  const newDefaultLogo = '/images/CornerstoneLogoColor.png';

  const isDefault =
    !org.logo || [oldDefaultLogo, newDefaultLogo].includes(org.logo);

  return (
    <>
      {!isDefault ? (
        <Image
          className={classes.logoWrapper}
          width={210}
          height={48}
          src={`${NEXT_PUBLIC_ORG_LOGOS_S3_BUCKET}${org.logo}`}
          alt='Site Logo'
        />
      ) : (
        <CornerstoneLogo
          className={classes.logoWrapper}
          colors={{
            primary: '#040059',
            secondary: '#ff8806',
            tertiary: '#4f88fc',
          }}
        />
      )}
    </>
  );
}

export default Logo;
