import React from 'react';

function SmallLogo({ ...props }) {
  return (
    <svg
      {...props}
      viewBox='0 0 35 35'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='favicon'>
          <path
            d='M29.837,34.997 L5.161,34.997 C2.323,34.997 0,32.675 0,29.836 L0,5.16 C0,2.322 2.323,0 5.161,0 L29.837,0 C32.675,0 34.998,2.322 34.998,5.16 L34.998,29.836 C34.998,32.675 32.675,34.997 29.837,34.997'
            id='Fill-1'
            fill='#040059'
          ></path>
          <path
            d='M18.0197,22.9279 C14.8167,22.9279 12.2107,20.3219 12.2107,17.1189 C12.2107,13.9159 14.8167,11.3109 18.0197,11.3109 C19.5707,11.3109 21.0297,11.9149 22.1277,13.0119 L20.3117,14.8279 C19.6997,14.2159 18.8857,13.8779 18.0197,13.8779 C16.2327,13.8779 14.7787,15.3319 14.7787,17.1189 C14.7787,18.9059 16.2327,20.3599 18.0197,20.3599 C18.8827,20.3599 19.6947,20.0249 20.3067,19.4159 L22.1177,21.2359 C21.0227,22.3269 19.5667,22.9279 18.0197,22.9279'
            id='Fill-2'
            fill='#FFFFFF'
          ></path>
          <path
            d='M18.0197,26.1125 C13.0607,26.1125 9.0267,22.0785 9.0267,17.1195 C9.0267,12.1605 13.0607,8.1265 18.0197,8.1265 C18.1507,8.1265 18.2807,8.1295 18.4107,8.1345 L18.3017,10.7005 C18.2077,10.6955 18.1147,10.6935 18.0197,10.6935 C14.4767,10.6935 11.5947,13.5765 11.5947,17.1195 C11.5947,20.6625 14.4767,23.5445 18.0197,23.5445 C19.7237,23.5445 21.3287,22.8845 22.5397,21.6855 L24.3457,23.5105 C22.6507,25.1885 20.4047,26.1125 18.0197,26.1125'
            id='Fill-3'
            fill='#4F84F8'
          ></path>
          <path
            d='M18.0197,29.3065 C11.2997,29.3065 5.8327,23.8395 5.8327,17.1195 C5.8327,10.3995 11.2997,4.9315 18.0197,4.9315 C21.2747,4.9315 24.3357,6.1995 26.6377,8.5015 L24.8217,10.3175 C23.0047,8.5005 20.5897,7.4995 18.0197,7.4995 C12.7157,7.4995 8.4007,11.8145 8.4007,17.1195 C8.4007,22.4235 12.7157,26.7385 18.0197,26.7385 C20.5867,26.7385 23.0007,25.7395 24.8177,23.9255 L26.6317,25.7425 C24.3307,28.0405 21.2717,29.3065 18.0197,29.3065'
            id='Fill-4'
            fill='#FF8406'
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SmallLogo;
