// the following function will only be called server side if the id token is expired
// as such, if it encounters an error it will return undefined, which
// will cause the jwt to be undefined and the user to be logged out,
// where they can refresh their token by re-logging in
// Since this runs server side the logging will be going to Vercel.
export default async function refreshToken(token, userPool) {
  const cognitoUser = userPool.getCurrentUser();
  const session = await new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log('getSession error', err);
        reject(err);
      }
      resolve(session);
    });
  });

  if (!session) return;

  const cognitoRefreshToken = session.getRefreshToken();
  const refreshedSession = await new Promise((resolve, reject) => {
    cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
      if (err) {
        console.log('refreshToken error', err);
        reject(err);
      }
      resolve(session);
    });
  });

  if (!refreshedSession) return;

  token.jwt = refreshedSession.getIdToken().getJwtToken();
  token.exp = refreshedSession.getIdToken().payload.exp;
  return token;
}
