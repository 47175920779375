import axios from 'axios';

export default async function uploadFileToS3(bucket, fileType, fileName, file) {
  // Get presigned URL from AWS to upload directly to s3.
  try {
    const presignedUrl = await axios.post(
      process.env.NEXT_PUBLIC_INVOKE_URL + '/upload-file',
      {
        bucket,
        fileType,
        fileName: fileName,
      }
    );
    // Upload file to S3 using presigned url.
    const formData = new FormData();
    formData.append('Content-Type', fileType);
    Object.entries(presignedUrl.data.url.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file);

    await axios.post(presignedUrl.data.url.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: [
        (data, headers) => {
          delete headers.Authorization;
          return data;
        },
      ],
    });
  } catch (e) {
    return { error: e };
  }
  return {};
}
