import React from 'react';
import Image from 'next/image';
import { Tooltip } from '@mui/material';

function TooltipIcon({ src, alt }) {
  return (
    <Tooltip title={alt}>
      {typeof src.src === 'string' ? (
        <Image priority src={src} alt={alt} />
      ) : (
        <RenderComponent Component={src} />
      )}
    </Tooltip>
  );
}

// This is a helper function to render a component
function RenderComponent({ Component }) {
  return <Component />;
}
export default TooltipIcon;
