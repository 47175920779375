const itemBaseStyle = {
  padding: '12px 16px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'neutrals.dark',
  textDecoration: 'none !important',
  transitionProperty: 'color, background',
  transitionDuration: '500ms',
  '&:focus-visible': {
    backgroundColor: 'primary.alpha.lighter',
    boxShadow: `inset 8px 0`,
    boxShadowColor: 'primary.main',
    color: 'primary.main',
  },
  '&:hover': {
    backgroundColor: 'primary.alpha.lighter',
    color: 'primary.main',
  },
  whiteSpace: 'nowrap',
};

export default itemBaseStyle;
