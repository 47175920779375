import moment from 'moment-timezone';
import { getCurrentTime } from './datetime';

export function getSnoozeEnd(eda, returnMoment = true) {
  const { snoozeEnd } = eda;
  const snoozeEndMoment = moment(snoozeEnd);
  return returnMoment ? snoozeEndMoment : snoozeEnd;
}

export function snoozeNonExistentOrExpired(eda) {
  if (eda?.snoozeEnd && eda?.snoozeEnd?.length) {
    return getCurrentTime('GMT', true).isSameOrAfter(getSnoozeEnd(eda));
  }
  return true;
}
