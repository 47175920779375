import React from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';
import itemBaseStyle from '@/components/LayoutTools/Sidebar/itemBaseStyle';

const activeItemStyle = {
  bgcolor: 'primary.alpha.lighter',
  boxShadow: 'inset 8px 0',
  boxShadowColor: 'primary.main',
  color: 'primary.main',
};

function SingleLinkModule({
  active,
  label,
  icon,
  url,
  sidebarWidth,
  toggleResize,
}) {
  const handleClick = (e) => {
    if (sidebarWidth < 80) {
      e.preventDefault();
      toggleResize();
    }
  };

  return (
    <Link onClick={handleClick} href={url} style={{ textDecoration: 'none' }}>
      <Box sx={{ ...itemBaseStyle, ...(active && activeItemStyle) }}>
        {!!icon && <Box>{icon}</Box>}
        <Box sx={{ marginLeft: '16px' }}>{label}</Box>
      </Box>
    </Link>
  );
}

export default SingleLinkModule;
