import React, { createContext } from 'react';

export const SessionContext = createContext({ user: {}, org: {}, token: '' });

export function SessionProvider({ session, children }) {
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}
