import { IS_PROD, AWS_REGION } from '@/utils/constants';
const credentials = {
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
};
// cognito configuration info
let cognitoConfig;
if (IS_PROD) {
  cognitoConfig = {
    region: AWS_REGION,
    poolId: 'us-east-1_GDcHEV6qU',
    clientId: '5g8ua5puqimf66lbk15ju7f9ne',
  };
} else {
  cognitoConfig = {
    region: AWS_REGION,
    poolId: 'us-east-2_4YQ99GNA1',
    clientId: '7n81v27crlfv6r0uh1o07alau0',
  };
}

// dynamo & ssm configuration info
const dynamoConfig = {
  region: AWS_REGION,
  credentials,
};

const sesConfig = {
  apiVersion: '2022-05-05',
  accessKeyId: process.env.EMAILER_KEY_ID,
  secretAccessKey: process.env.EMAILER_KEY_SECRET,
};

const ORG_TABLE_NAME = 'Organizations.V1';
const DATES_TABLE_NAME = 'SignificantDates.V1';
const IQCX_ACCTS_TABLE_NAME = 'IqcxAccounts.V1';
const CORNERSTONE_INDEX_TABLE_NAME = 'CornerstoneIndex.V1';

let origin = '';
if (typeof document !== 'undefined') {
  origin = document.location.origin;
}

const amplifyConfig = {
  region: AWS_REGION,
  userPoolId: cognitoConfig.poolId,
  userPoolWebClientId: cognitoConfig.clientId,
  oauth: {
    domain: 'cissso.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: origin + '/sso',
    redirectSignOut: origin,
    responseType: 'token',
  },
};

if (!IS_PROD) {
  amplifyConfig.oauth.domain = 'tk421.auth.us-east-2.amazoncognito.com';
}

export {
  cognitoConfig,
  dynamoConfig,
  ORG_TABLE_NAME,
  DATES_TABLE_NAME,
  IQCX_ACCTS_TABLE_NAME,
  CORNERSTONE_INDEX_TABLE_NAME,
  sesConfig,
  amplifyConfig,
};
