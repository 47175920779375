function configHasPermission(config, permissionPath) {
  // Optionally assume that the first key is config.
  if (permissionPath[0] !== 'config') {
    permissionPath.unshift('config');
  }

  let pointer = config;

  for (let key of permissionPath) {
    if (!pointer?.[key]) return false;
    pointer = pointer[key];
  }

  return true;
}

function hasPermissions(userConfig, orgConfig, permissionPath) {
  // If user is internal or an admin, they should have access to
  // everything in the org.
  const orgHasPermission = configHasPermission(orgConfig, permissionPath);
  if (userConfig.isAdmin) return orgHasPermission;

  // End users must be configured for everything.
  const userHasPermission = configHasPermission(userConfig, permissionPath);
  return userHasPermission && orgHasPermission;
}

export default hasPermissions;
