import JSZip from 'jszip';

/**
 * Downloads files from S3 based on provided URLs and customize File Name.
 * @param {Array<Object>} files - An array of objects containing file information.
 * [{ fileKey: "1330608891_1.json", url: "https://cis-processing-raw.s3.us-east-2.amazonaws.com/Portlog/  10223/649200/2023/10/26/06/1330608891_1.json" }]
 * @param {string} customizeFileName - A name used for customizing filenames.
 * @param {string} ZipFileName - A name give to downloaded Zip File.
 * @param {function} customDecodeFunction - A custom function for decoding.
 */

export default async function downloadFilesFromS3(
  files,
  customizeFileName,
  zipFileName,
  customDecodeFunction
) {
  // Function to fetch the file from a presigned URL
  async function fetchFile(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.blob();
  }

  const zip = new JSZip();

  try {
    const fetchPromises = files
      .filter((data) => data?.url)
      .map(async (data) => {
        const fileBlob = fetchFile(data.url);
        let fileContent = await fileBlob.then((blob) => blob.text());

        if (data.fileKey.endsWith('.json')) {
          fileContent = JSON.parse(fileContent);
          if (typeof customDecodeFunction === 'function') {
            fileContent = customDecodeFunction(fileContent);
          }
        }
        const fileName = data.fileKey.split('/').pop();
        const updatedFileName = fileName.replace(
          /^(\d+)_/,
          `${customizeFileName}_`
        );
        zip.file(updatedFileName, fileContent, { binary: false });
      });

    await Promise.all(fetchPromises);

    const content = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(content);
    link.download = `${zipFileName}.zip`;
    link.dispatchEvent(new MouseEvent('click'));
  } catch (error) {
    console.error('Error:', error);
  }
}
