import { useState, useEffect } from 'react';
import useSession from './useSession';

function getFromLocalStorage(key, initialValue) {
  // if we get here from rendering on the server, we don't have access to localStorage
  // so we return the initial value, also for unit tests
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
    const storedValue = localStorage.getItem(key);
    try {
      const temp = JSON.parse(storedValue);
      if (typeof temp === 'object') {
        return temp || initialValue;
      }
    } catch (e) {
      return initialValue;
    }
  }
  return initialValue;
}

const useLocalStorage = (key, initialValue) => {
  const { user } = useSession();
  const compositeKey = `${key}_${user?.email}`;

  const [value, setValue] = useState(
    getFromLocalStorage(compositeKey, JSON.parse(JSON.stringify(initialValue)))
  );

  useEffect(() => {
    localStorage.setItem(compositeKey, JSON.stringify(value));
  }, [compositeKey, value]);

  return [value, setValue];
};

export default useLocalStorage;
