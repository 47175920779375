import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Checkbox,
  Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useLog } from '@/utils/hooks';
import Loader from '@/components/Reusable/GeneralUse/Loader';
import { getBrowserInfo } from '@/utils';
import { useSnackBarContext } from '@/utils/snackbar';
import { useSession } from '@/utils/hooks';
import useAWSPost from '@/utils/hooks/api/aws/useAWSPost';

const CustomerSupportModal = ({ isOpen, onClose }) => {
  const [feedbackType, setfeedbackType] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [helpLabel, setHelpLabel] = useState('');
  const [followUpState, setFollowUpState] = useState(false);
  const { user, org } = useSession();
  const { name, version } = getBrowserInfo();
  const { updateSnack } = useSnackBarContext();
  const { log } = useLog('FeedBack');

  const feedbackInfo = {
    ask_a_question: {
      label: 'Ask a question',
      hub_intro_text: 'What can we help with?',
      follow_up_state: true,
      email_subject_prefix: 'Cornerstone Hub-Question',
      email_body_desc: 'a question about',
    },
    tell_us_idea: {
      label: 'Tell us your idea',
      hub_intro_text: 'What is your suggestion?',
      follow_up_state: false,
      email_subject_prefix: 'Cornerstone Hub-Idea',
      email_body_desc: 'an idea for',
    },
    report_problem: {
      label: 'Report a problem',
      hub_intro_text: 'What issue did you encounter?',
      follow_up_state: true,
      email_subject_prefix: 'Cornerstone Hub-Problem',
      email_body_desc: 'encountered a problem in',
    },
  };

  function handleFeedbackChange(value) {
    setError(false);
    if (!value) setError(true);
    setfeedbackType(value);
    setHelpLabel(feedbackInfo[value]?.hub_intro_text);
    setFollowUpState(feedbackInfo[value]?.follow_up_state);
  }

  const { request, isLoading, data, fire } = useAWSPost(
    `/hub-feedback/submit`,
    {
      fireOnMount: false,
      skip: true,
    }
  );

  useEffect(() => {
    if (request.isSuccess) {
      setfeedbackType('');
      setFollowUpState(false);
      updateSnack({
        severity: 'success',
        message: `Feedback submitted successfully.`,
      });
      onClose();
      log({
        status: 'Info',
        message: `Feedback type: ${feedbackInfo[feedbackType]?.label} received; Feedback message: ${message}`,
      });
    }

    if (request.isError) {
      raiseError();
      onClose();
    }
  }, [data, isLoading]);

  function raiseError(msg) {
    updateSnack({
      severity: 'error',
      message: `${msg}`,
    });
  }

  function submitFeeback() {
    if (!feedbackType) {
      setError(true);
      raiseError('There is a problem sending feedback.');
      return false;
    }

    fire({
      formData: feedbackInfo[feedbackType],
      message: message,
      followUpFlag: followUpState,
      userDetails: {
        userName: `${user?.firstName} ${user?.lastName}`,
        userEmail: user?.email,
        orgInfo: `${org?.displayName} - ${org?.name}`,
      },
      sessionDetails: {
        currentUrl: window.location.href,
        browserInfo: `${name} & ${version}`,
      },
    });
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle variant='h4'>Give Feedback</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ m: 1 }}>
            <DialogContentText sx={{ mb: 2, color: 'neutrals.dark' }}>
              Your feedback on the Cornerstone Hub is important to us.
            </DialogContentText>
            <FormControl required fullWidth error={error}>
              <InputLabel id='type-of-feedback-label'>
                Choose type of feedback...
              </InputLabel>
              <Select
                labelId='type-of-feedback-label'
                id='type-of-feedback'
                label='Choose type of feedback...'
                onChange={(e) => handleFeedbackChange(e.target.value)}
                value={feedbackType}
                sx={{ width: '35%' }}
              >
                {Object.keys(feedbackInfo).map((key) => {
                  const item = feedbackInfo[key];
                  return (
                    <MenuItem key={key} value={key}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
                ;
              </Select>

              <Typography
                sx={{
                  margin: '0.5rem 0',
                  color: 'neutrals.dark',
                }}
              >
                {helpLabel}
              </Typography>
              <Box>
                <TextField
                  id='message'
                  placeholder='Write your message here...'
                  label='Write your message here...'
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Box>
              <FormControlLabel
                value='end'
                sx={{ color: 'grey' }}
                control={
                  <Checkbox
                    checked={followUpState}
                    value={followUpState}
                    onChange={() => setFollowUpState(!followUpState)}
                  />
                }
                label='Please follow-up with me'
                labelPlacement='end'
              />
              <p style={{ color: 'grey', fontSize: '12px', marginTop: '1px' }}>
                Your message and some session details will be sent to
                Cornerstone Support
              </p>
              <Box
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'inline-flex',
                }}
              >
                <Button
                  variant='contained'
                  color='success'
                  startIcon={<SendIcon />}
                  onClick={() => submitFeeback()}
                >
                  Send
                </Button>
              </Box>
            </FormControl>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomerSupportModal;
