function getBrowserInfo() {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent;
    let browserName = '';
    let browserVersion = '';

    if (userAgent.includes('Firefox')) {
      browserName = 'Firefox';
      browserVersion = userAgent.split('Firefox/')[1];
    } else if (userAgent.includes('Chrome')) {
      browserName = 'Chrome';
      browserVersion = userAgent.split('Chrome/')[1];
    } else if (userAgent.includes('Safari')) {
      browserName = 'Safari';
      browserVersion = userAgent.split('Version/')[1];
    } else if (userAgent.includes('Edge')) {
      browserName = 'Edge';
      browserVersion = userAgent.split('Edge/')[1];
    } else if (userAgent.includes('MSIE')) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent.split('MSIE')[1];
    }

    return {
      name: browserName,
      version: browserVersion.split(' ')[0],
    };
  }

  return {
    name: '',
    version: '',
  };
}

export default getBrowserInfo;
