import React from 'react';

function CornerstoneLogo({ white, ...props }) {
  const whiteColor = '#ffffff';
  let primary = white ? whiteColor : '#040059';
  let secondary = white ? whiteColor : '#ff8806';
  let tertiary = white ? whiteColor : '#4f88fc';

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 720 216'
      style={{
        enableBackground: 'new 0 0 720 216',
        stroke: primary,
        fill: primary,
      }}
      {...props}
    >
      {/* whole thing */}
      <g>
        {/* cornerstone text */}
        <g style={{ stroke: primary, fill: primary }}>
          <g>
            <path
              className='st0'
              d='M248.31,129.58c-1.52,0-3.04-0.15-4.55-0.46c-1.82-0.33-3.62-0.93-5.34-1.78c-1.72-0.84-3.32-1.91-4.76-3.16
				c-1.42-1.23-2.69-2.63-3.79-4.16c-1.12-1.56-2.02-3.24-2.68-4.99c-0.97-2.64-1.47-5.31-1.5-7.93c-0.03-2.62,0.36-5.13,1.15-7.48
				c0.78-2.32,1.93-4.5,3.42-6.48c1.5-1.99,3.3-3.7,5.37-5.08c2.04-1.37,4.32-2.43,6.77-3.15c2.5-0.73,5.17-1.02,7.96-0.86
				c2.12,0.11,4.18,0.57,6.1,1.36c1.76,0.72,3.46,1.61,5.06,2.65l0.1,0.07c1.8,1.25,3.37,2.73,4.66,4.37
				c1.24,1.58,2.32,3.34,3.21,5.23c0.87,1.91,1.4,3.87,1.59,5.88c0.18,1.78,0.22,3.59,0.12,5.39c-0.01,1.21-0.18,2.41-0.52,3.55
				c-0.28,0.94-0.6,1.86-0.96,2.74c-0.99,2.61-2.42,4.93-4.25,6.91c-1.82,1.95-3.92,3.55-6.25,4.74c-2.31,1.18-4.82,1.98-7.46,2.38
				C250.61,129.49,249.46,129.58,248.31,129.58z M248.45,92.63c-0.65,0-1.29,0.05-1.92,0.14c-2.57,0.41-4.74,1.36-6.61,2.89
				c-1.93,1.59-3.34,3.41-4.31,5.57c-0.96,2.14-1.35,4.39-1.2,6.87c0.15,2.4,0.96,4.62,2.49,6.78c1.11,1.65,2.42,2.91,4.05,3.9
				c1.66,1.02,3.4,1.66,5.32,1.96l0.11,0.02c1.52,0.29,3.03,0.28,4.62-0.03c1.65-0.32,3.16-0.86,4.5-1.6
				c1.75-0.97,3.17-2.16,4.24-3.55c1.09-1.42,1.87-2.96,2.33-4.56c0.46-1.63,0.63-3.33,0.5-5.03c-0.13-1.71-0.56-3.38-1.26-4.94
				c-0.7-1.56-1.71-3.01-2.99-4.3c-1.26-1.27-2.81-2.32-4.61-3.11c-0.99-0.41-2.18-0.72-3.46-0.88
				C249.64,92.66,249.04,92.63,248.45,92.63z'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M285.29,128.7h-8.62V85.44h8.62v4.87c0.65-0.53,1.32-1.03,2.01-1.5c1.78-1.2,3.7-2.13,5.72-2.76
				c2.09-0.65,4.28-0.92,6.53-0.81l3.27,0.16v8.44l-3.36,0.08c-1.65,0.04-3.24,0.33-4.73,0.87c-1.5,0.54-2.87,1.3-4.07,2.24
				c-1.21,0.94-2.26,2.09-3.14,3.42c-0.82,1.24-1.42,2.62-1.79,4.12c-0.03,0.25-0.09,0.54-0.17,0.92c-0.03,0.14-0.05,0.26-0.07,0.35
				c-0.01,0.39-0.05,0.85-0.14,1.43c-0.03,0.2-0.05,0.35-0.07,0.46V128.7z M285.29,107.59v0.04
				C285.29,107.62,285.29,107.6,285.29,107.59z M285.49,105.72c0,0.02,0,0.04,0,0.05C285.49,105.75,285.49,105.74,285.49,105.72z'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M354.03,128.7h-8.48v-22.5c0-0.92-0.18-1.92-0.52-2.98c-0.38-1.17-0.84-2.22-1.37-3.11
				c-0.92-1.6-2.01-2.93-3.26-3.98c-1.26-1.06-2.66-1.87-4.15-2.42c-1.5-0.56-3.08-0.86-4.67-0.89c-1.59-0.04-3.16,0.19-4.65,0.67
				c-1.5,0.48-2.94,1.24-4.29,2.25c-1.3,0.98-2.45,2.25-3.41,3.8c-0.54,0.93-1.04,2.01-1.5,3.22c-0.38,1.01-0.58,1.97-0.58,2.84
				v23.12h-8.62V85.09h8.62v3.92c0.09-0.06,0.17-0.13,0.26-0.19c2.6-1.99,5.54-3.36,8.72-4.06c3.17-0.7,6.33-0.76,9.4-0.2
				c3.08,0.57,6,1.75,8.69,3.53c2.75,1.82,4.98,4.23,6.63,7.19c0.83,1.41,1.56,2.96,2.15,4.59c0.67,1.83,1.01,3.68,1.01,5.51V128.7z
				'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M419.71,128.7h-8.62V85.44h8.62v4.87c0.65-0.53,1.32-1.03,2.01-1.5c1.78-1.2,3.7-2.13,5.72-2.76
				c2.09-0.65,4.28-0.92,6.53-0.81l3.27,0.16v8.44l-3.36,0.08c-1.65,0.04-3.24,0.33-4.73,0.87c-1.5,0.54-2.87,1.3-4.07,2.24
				c-1.21,0.94-2.26,2.09-3.14,3.42c-0.82,1.24-1.42,2.62-1.79,4.12c-0.03,0.25-0.09,0.54-0.17,0.92c-0.03,0.14-0.05,0.26-0.07,0.35
				c-0.01,0.39-0.05,0.85-0.14,1.43c-0.03,0.2-0.05,0.35-0.07,0.46V128.7z M419.71,107.59v0.04
				C419.71,107.62,419.71,107.6,419.71,107.59z M419.92,105.72c0,0.02,0,0.04,0,0.05C419.92,105.75,419.92,105.74,419.92,105.72z'
            />
          </g>
          <g>
            <polygon
              className='st0'
              points='500.13,128.7 491.37,128.7 491.37,93.95 481.17,93.95 481.17,85.27 491.37,85.27 491.37,64.31 
				500.13,64.31 500.13,85.27 511.01,85.27 511.01,93.95 500.13,93.95 			'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M534.56,129.58c-1.52,0-3.04-0.15-4.55-0.46c-1.82-0.33-3.62-0.93-5.34-1.78c-1.72-0.84-3.32-1.91-4.76-3.16
				c-1.42-1.23-2.69-2.63-3.79-4.16c-1.12-1.56-2.02-3.24-2.68-4.99c-0.97-2.64-1.47-5.31-1.5-7.93c-0.03-2.62,0.36-5.13,1.15-7.48
				c0.78-2.32,1.93-4.5,3.42-6.48c1.5-1.99,3.3-3.7,5.37-5.08c2.04-1.37,4.32-2.43,6.77-3.15c2.5-0.73,5.17-1.02,7.96-0.86
				c2.12,0.11,4.18,0.57,6.1,1.36c1.76,0.72,3.46,1.61,5.06,2.65l0.1,0.07c1.8,1.25,3.37,2.73,4.66,4.37
				c1.24,1.58,2.32,3.34,3.21,5.23c0.87,1.91,1.4,3.87,1.59,5.88c0.18,1.78,0.22,3.59,0.12,5.39c-0.01,1.21-0.18,2.41-0.52,3.55
				c-0.28,0.94-0.6,1.86-0.96,2.74c-0.99,2.61-2.42,4.93-4.25,6.91c-1.82,1.95-3.92,3.55-6.25,4.74c-2.31,1.18-4.82,1.98-7.46,2.38
				C536.86,129.49,535.71,129.58,534.56,129.58z M534.7,92.63c-0.65,0-1.29,0.05-1.92,0.14c-2.57,0.41-4.74,1.36-6.61,2.89
				c-1.93,1.59-3.34,3.41-4.31,5.57c-0.96,2.14-1.35,4.39-1.2,6.87c0.15,2.4,0.96,4.62,2.49,6.78c1.11,1.65,2.42,2.91,4.05,3.9
				c1.66,1.02,3.4,1.66,5.32,1.96l0.11,0.02c1.52,0.29,3.03,0.28,4.62-0.03c1.65-0.32,3.16-0.86,4.5-1.6
				c1.75-0.97,3.17-2.16,4.24-3.55c1.09-1.42,1.87-2.96,2.33-4.56c0.46-1.63,0.63-3.33,0.5-5.03c-0.13-1.71-0.56-3.38-1.26-4.94
				c-0.7-1.56-1.71-3.01-2.99-4.3c-1.26-1.27-2.81-2.32-4.61-3.11c-0.99-0.41-2.18-0.72-3.46-0.88
				C535.89,92.66,535.29,92.63,534.7,92.63z'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M607.51,128.7h-8.48v-22.5c0-0.92-0.18-1.92-0.52-2.98c-0.38-1.17-0.84-2.22-1.37-3.11
				c-0.91-1.6-2.01-2.93-3.26-3.98c-1.26-1.06-2.66-1.87-4.15-2.42c-1.5-0.56-3.08-0.86-4.67-0.89c-1.59-0.04-3.16,0.19-4.65,0.67
				c-1.5,0.48-2.94,1.24-4.29,2.25c-1.3,0.98-2.45,2.25-3.41,3.8c-0.54,0.93-1.04,2.01-1.5,3.22c-0.38,1.01-0.58,1.97-0.58,2.84
				v23.12h-8.62V85.09h8.62v3.92c0.09-0.06,0.17-0.13,0.26-0.19c2.6-1.99,5.54-3.36,8.72-4.06c3.17-0.7,6.33-0.76,9.4-0.2
				c3.08,0.57,6,1.75,8.69,3.53c2.75,1.82,4.98,4.23,6.63,7.19c0.83,1.41,1.56,2.96,2.15,4.59c0.67,1.83,1.01,3.68,1.01,5.51V128.7z
				'
            />
          </g>
          <g>
            <g>
              <path
                className='st0'
                d='M460.01,130.28c-11.48,0-19.21-5.43-20.69-14.53l-0.56-3.44h8.63l0.51,2.34c1.27,5.87,7.25,7.1,12.03,7.1
					c3.62,0,6.82-1.08,8.57-2.88c0.75-0.79,1.12-1.84,1.03-2.99l-0.01-0.13c-0.05-1.51-0.13-4.03-10.75-5.55l-0.21-0.03
					c-6.8-0.99-17.07-2.49-17.27-12.51c0-6.7,5.99-13.43,17.45-13.43c9.85,0,16.61,4.73,18.56,12.98l0.86,3.65h-8.77l-0.64-2.1
					c-1.21-3.98-4.55-5.99-9.94-5.99c-3.33,0-6.23,0.93-7.75,2.48c-0.69,0.7-1.03,1.48-1.02,2.31c0.02,1.47,0.79,2.8,10,4.09
					c5.76,0.86,17.69,2.64,18.23,13.79c0.1,3.63-1.17,7.06-3.5,9.48l-0.01,0.01C471.4,128.38,466.16,130.28,460.01,130.28z'
              />
            </g>
          </g>
          <path
            className='st0'
            d='M405.07,106.98c0.16-3.71-0.45-7.14-1.82-10.19c-1.35-3-3.22-5.57-5.57-7.64c-0.49-0.43-1.01-0.83-1.53-1.22
			l0-0.01c-0.02-0.02-0.05-0.03-0.07-0.05c-0.38-0.28-0.78-0.54-1.18-0.8c-0.17-0.11-0.34-0.23-0.52-0.34
			c-1.46-0.89-3.01-1.65-4.66-2.22c-2.97-1.02-6.05-1.44-9.16-1.22c-3.11,0.22-6.16,1.09-9.07,2.6c-2.96,1.54-5.58,3.84-7.78,6.84
			l-0.06,0.08c-1.07,1.55-1.99,3.31-2.73,5.21c-0.78,1.99-1.23,4.03-1.34,6.12c-0.09,2.63,0.2,5.22,0.82,7.7
			c0.01,0.02,0.01,0.05,0.02,0.07c0.08,0.31,0.14,0.63,0.23,0.93c0.29,0.98,0.67,1.92,1.1,2.85c0,0,0,0,0,0
			c0.24,0.52,0.48,1.03,0.76,1.53c0.06,0.11,0.11,0.22,0.17,0.33c0.91,1.64,2.06,3.16,3.4,4.54c1.32,1.35,2.79,2.54,4.37,3.54
			c1.61,1.02,3.35,1.83,5.18,2.41c1.83,0.58,3.69,0.9,5.54,0.96c1.54,0.08,3.06,0,4.57-0.23c1.14-0.17,2.26-0.43,3.36-0.77
			c2.55-0.79,4.92-1.96,7.02-3.47c1.82-1.31,3.43-2.88,4.79-4.68l-6.97-5.13c-0.73,0.96-1.63,1.84-2.68,2.65
			c-1.21,0.94-2.63,1.7-4.21,2.26c-1.53,0.55-3.02,0.78-4.56,0.72l-0.12,0c-1.94-0.01-3.76-0.38-5.55-1.14
			c-1.75-0.74-3.24-1.79-4.59-3.25c-1.67-1.73-2.73-3.61-3.3-5.7h35.96L405.07,106.98z M369.35,101.45
			c0.66-1.79,1.64-3.42,3.01-4.94c1.62-1.8,3.62-3.06,6.1-3.85c0.61-0.19,1.23-0.33,1.88-0.43c0.59-0.09,1.18-0.14,1.79-0.15
			c1.3-0.03,2.52,0.1,3.56,0.35c1.9,0.51,3.59,1.31,5.02,2.38c0.08,0.06,0.16,0.12,0.24,0.18l0.07-0.09
			c2.19,1.77,3.73,3.93,4.63,6.55H369.35z'
          />
          <path
            className='st0'
            d='M657.99,106.98c0.16-3.71-0.45-7.14-1.82-10.19c-1.35-3-3.22-5.57-5.57-7.64c-0.49-0.43-1.01-0.83-1.53-1.22
			l0-0.01c-0.02-0.02-0.05-0.03-0.07-0.05c-0.38-0.28-0.78-0.54-1.18-0.8c-0.17-0.11-0.34-0.23-0.52-0.34
			c-1.46-0.89-3.01-1.65-4.66-2.22c-2.97-1.02-6.05-1.44-9.16-1.22c-3.11,0.22-6.16,1.09-9.07,2.6c-2.96,1.54-5.58,3.84-7.78,6.84
			l-0.06,0.08c-1.07,1.55-1.99,3.31-2.73,5.21c-0.78,1.99-1.23,4.03-1.34,6.12c-0.09,2.63,0.2,5.22,0.82,7.7
			c0.01,0.02,0.01,0.05,0.02,0.08c0.08,0.31,0.14,0.62,0.23,0.93c0.29,0.98,0.67,1.92,1.1,2.85c0,0,0,0,0,0
			c0.24,0.52,0.48,1.03,0.76,1.53c0.06,0.11,0.11,0.22,0.17,0.33c0.91,1.64,2.06,3.16,3.4,4.54c1.32,1.35,2.79,2.54,4.37,3.54
			c1.61,1.02,3.35,1.83,5.18,2.41c1.83,0.58,3.69,0.9,5.54,0.96c1.54,0.08,3.06,0,4.57-0.23c1.14-0.17,2.26-0.43,3.36-0.77
			c2.55-0.79,4.92-1.96,7.02-3.47c1.82-1.31,3.43-2.88,4.79-4.68l-6.97-5.13c-0.73,0.96-1.63,1.84-2.68,2.65
			c-1.21,0.94-2.63,1.7-4.21,2.26c-1.53,0.55-3.02,0.78-4.56,0.72l-0.11,0c-1.94-0.01-3.76-0.38-5.55-1.14
			c-1.75-0.74-3.24-1.79-4.59-3.25c-1.67-1.73-2.73-3.61-3.3-5.7h35.96L657.99,106.98z M622.28,101.45
			c0.66-1.79,1.64-3.42,3.01-4.94c1.62-1.8,3.62-3.06,6.1-3.85c0.61-0.19,1.23-0.33,1.88-0.43c0.59-0.09,1.18-0.14,1.79-0.15
			c1.3-0.03,2.52,0.1,3.56,0.35c1.9,0.51,3.59,1.31,5.02,2.38c0.08,0.06,0.16,0.12,0.24,0.18l0.07-0.09
			c2.19,1.77,3.73,3.93,4.63,6.55H622.28z'
          />
          <g>
            <path
              className='st0'
              d='M224.93,122.05c-1.62,1.58-3.45,2.89-5.44,3.91c-2.31,1.18-4.82,1.98-7.46,2.38
				c-1.14,0.17-2.29,0.26-3.44,0.26c-1.52,0-3.04-0.15-4.55-0.46c-1.82-0.33-3.62-0.93-5.34-1.78c-1.72-0.84-3.32-1.91-4.76-3.16
				c-1.42-1.23-2.69-2.63-3.79-4.16c-1.12-1.56-2.02-3.24-2.68-4.99c-0.97-2.64-1.47-5.31-1.5-7.93c-0.03-2.62,0.36-5.13,1.15-7.48
				c0.78-2.32,1.93-4.5,3.42-6.48c1.5-1.99,3.3-3.7,5.37-5.08c2.04-1.37,4.32-2.43,6.77-3.15c2.5-0.73,5.17-1.02,7.96-0.86
				c2.12,0.11,4.18,0.57,6.1,1.36c1.76,0.72,3.46,1.61,5.06,2.65l0.1,0.07c1.12,0.78,2.16,1.65,3.09,2.6l-6.19,6.22
				c-0.07-0.07-0.14-0.14-0.21-0.22c-1.26-1.27-2.81-2.32-4.61-3.11c-0.99-0.41-2.18-0.72-3.46-0.88c-0.6-0.08-1.2-0.12-1.8-0.12
				c-0.65,0-1.29,0.05-1.92,0.14c-2.57,0.41-4.74,1.36-6.61,2.89c-1.93,1.59-3.34,3.41-4.31,5.57c-0.96,2.14-1.35,4.39-1.2,6.87
				c0.15,2.4,0.96,4.62,2.49,6.78c1.11,1.65,2.42,2.91,4.05,3.9c1.66,1.02,3.4,1.66,5.32,1.96l0.11,0.02
				c1.52,0.29,3.03,0.28,4.62-0.03c1.65-0.32,3.16-0.86,4.5-1.6c1.16-0.64,2.18-1.39,3.05-2.22L224.93,122.05z'
            />
          </g>
        </g>
        {/* c logo */}
        <g>
          {/* innermost c */}
          <g style={{ stroke: primary, fill: primary }}>
            <path
              className='st1'
              d='M126.64,138.71c-17.27,0-31.31-14.16-31.31-31.56c0-17.41,14.05-31.56,31.31-31.56
				c8.36,0,16.23,3.28,22.14,9.25l-9.79,9.87c-3.3-3.33-7.69-5.16-12.36-5.16c-9.63,0-17.47,7.9-17.47,17.61
				c0,9.71,7.84,17.61,17.47,17.61c4.65,0,9.03-1.82,12.33-5.13l9.77,9.89C142.83,135.44,134.98,138.71,126.64,138.71z'
            />
          </g>
          {/* middle c */}
          <g style={{ stroke: tertiary, fill: tertiary }}>
            <path
              className='st2'
              d='M126.64,156.01c-26.73,0-48.48-21.92-48.48-48.87s21.75-48.87,48.48-48.87c0.71,0,1.41,0.02,2.11,0.05
				l-0.59,13.94c-0.5-0.02-1.01-0.03-1.52-0.03c-19.1,0-34.64,15.66-34.64,34.92s15.54,34.92,34.64,34.92
				c9.18,0,17.84-3.59,24.37-10.1l9.74,9.92C151.61,150.99,139.5,156.01,126.64,156.01z'
            />
          </g>
          {/* outer c */}
          <g style={{ stroke: secondary, fill: secondary }}>
            <path
              className='st3'
              d='M126.64,173.37c-36.23,0-65.7-29.71-65.7-66.22s29.47-66.22,65.7-66.22c17.55,0,34.05,6.89,46.46,19.4
				l-9.79,9.87c-9.8-9.87-22.82-15.31-36.67-15.31c-28.59,0-51.86,23.45-51.86,52.27s23.26,52.27,51.86,52.27
				c13.84,0,26.85-5.43,36.65-15.29l9.78,9.87C160.66,166.49,144.17,173.37,126.64,173.37z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CornerstoneLogo;
