import demoNavigationItems from '@/components/LayoutTools/Sidebar/demoNavigationItems';
import navigationItems from '@/components/LayoutTools/Sidebar/navigationItems';
import hasPermissions from '@/utils/auth/hasPermissions';

export default function getPermissionedItems(user, org) {
  if (hasPermissions(user, org, ['demoNavigation'])) {
    return demoNavigationItems;
  }

  return filterItems(navigationItems, user, org);
}

export function filterItems(items, user, org) {
  return items
    .filter((item) => !item.adminRequired || user.isAdmin)
    .filter((item) => !item.internalRequired || user.isInternal)
    .filter((item) => item.configPath !== 'donotuse')
    .filter(
      (item) =>
        !item.configPath ||
        hasPermissions(user, org, item.configPath.split('.'))
    );
}
