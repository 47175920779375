import { DATES_TABLE_NAME } from '@/utils/aws/configs';
import { getCurrentTime } from '@/utils/datetime';
import { commandCall } from '@/utils/request';

export default async function getSignificantDates() {
  const today = getCurrentTime();

  const response = await commandCall('QueryCommand', {
    TableName: DATES_TABLE_NAME,
    KeyConditionExpression: 'PK = :pk and SK >= :sk',
    ExpressionAttributeValues: {
      ':pk': 'V1',
      ':sk': today,
    },
  });

  const significantDates = response.Items.sort((item) => item.SK);
  return significantDates || [];
}
