import React from 'react';
import SidebarItem from './SidebarItem';
import Logo from '../Header/Logo';
import { Box } from '@mui/material';
import { useSession } from '@/utils/hooks';
import getPermissionedItems from '@/components/LayoutTools/Sidebar/permissionHelpers';
import PoweredByLogo from '@/components/LayoutTools/Sidebar/PoweredByLogo';

export default function SidebarBase({ isMobile, sidebarWidth, toggleResize }) {
  const { user, org } = useSession();

  let items = getPermissionedItems(user, org);

  return (
    <Box
      component='nav'
      sx={{
        width: '100%',
        bgcolor: 'white',
        borderRight: '1px solid #E0E0E0',
        flexShrink: 0,
        height: '100%',
        userSelect: 'none',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        '&:hover': {
          '&::-webkit-scrollbar': {
            display: 'inline',
          },
          scrollbarWidth: 'thin',
        },
      }}
    >
      <Box>
        {isMobile && <Logo />}
        {items.map((item) => {
          return (
            <SidebarItem
              key={item.label + item.configPath}
              sidebarWidth={sidebarWidth}
              toggleResize={toggleResize}
              {...item}
            />
          );
        })}
      </Box>
      {!org.isDefaultLogo && <PoweredByLogo sidebarWidth={sidebarWidth} />}
    </Box>
  );
}
