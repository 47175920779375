import React from 'react';
import CornerstoneLogo from '@/assets/logos/CornerstoneLogo';
import { Box, Tooltip, Typography } from '@mui/material';
import SmallLogo from '@/assets/logos/SmallLogo';
import itemBaseStyle from '@/components/LayoutTools/Sidebar/itemBaseStyle';

export default function PoweredByLogo({ sidebarWidth }) {
  return (
    <a
      href='https://www.ciswired.com'
      target='_blank'
      rel='noreferrer'
      style={{ textDecoration: 'none' }}
    >
      <Box
        sx={{
          ...itemBaseStyle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          pb: 1,
        }}
      >
        {sidebarWidth < 80 ? (
          <Tooltip title='Powered by Cornerstone'>
            <SmallLogo height='24px' colors={{ primary: '#040059' }} />
          </Tooltip>
        ) : (
          <>
            <Typography variant='caption'>Powered by</Typography>
            <CornerstoneLogo width='100px' colors={{ primary: '#040059' }} />
          </>
        )}
      </Box>
    </a>
  );
}
