import React from 'react';
import TooltipIcon from '@/components/LayoutTools/Sidebar/TooltipIcon';
import HomeIcon from '../../../public/images/Icon-Home.svg';
import EDAIcon from '../../../public/images/Icon-EDA.svg';
import TravelAuthorizationIcon from '../../../public/images/Icon-TravelAuthorization.svg';
import iBankReportingIcon from '../../../public/images/Icon-Reporting.svg';
import WorkflowIcon from '../../../public/images/Icon-Workflow.svg';
import AdministrationIcon from '../../../public/images/Icon-Administration.svg';
import DataInsightsIcon from '../../../public/images/Icon-Data-Insights.svg';
import { ManageAccounts } from '@mui/icons-material';

const navigationItems = [
  {
    label: 'Home',
    icon: <TooltipIcon src={HomeIcon} alt='Home' />,
    url: '/hub',
  },
  // reporting and analytics for etsNext demo
  {
    label: 'Demo-Reporting and Analytics',
    icon: <TooltipIcon src={DataInsightsIcon} alt='Reporting and Analytics' />,
    configPath: 'reportingets.active',
    items: [
      {
        label: 'Air Reports',
        url: '/reporting/air-reports',
        configPath: 'reportingets.airReports',
      },
      {
        label: 'Authorization Reports',
        url: '/reporting/authorization-reports',
        configPath: 'reportingets.authorizationReports',
      },
      {
        label: 'Hotel Reports',
        url: '/reporting/hotel-reports',
        configPath: 'reportingets.hotelReports',
      },
      {
        label: 'Expense Reports',
        url: '/reporting/expense-reports',
        configPath: 'reportingets.expenseReports',
      },
    ],
  },
  // more for etsNext
  {
    label: 'Solution Verification Reports',
    icon: (
      <TooltipIcon
        src={iBankReportingIcon}
        alt='Solution Verification Reports'
      />
    ),
    configPath: 'solutionVerification.active',
    items: [
      {
        label: 'Predefined Reports',
        url: '/solution-verification/predefined-reports/travel-expense-summary',
        configPath: 'solutionVerification.active',
      },
      {
        label: 'Ad Hoc Reports',
        url: '/solution-verification/ad-hoc-reports/tdy-vouchers-expense-detail',
        configPath: 'solutionVerification.active',
      },
    ],
  },
  {
    label: 'Reporting and Analytics',
    icon: <TooltipIcon src={DataInsightsIcon} alt='Reporting and Analytics' />,
    configPath: 'reporting.active',
    items: [
      {
        label: 'Record Processing',
        url: '/reporting/record-processing',
        configPath: 'reporting.recordProcessing',
      },
      {
        label: 'Errors and Exceptions',
        url: '/reporting/errors',
        configPath: 'reporting.errors',
      },
      {
        label: 'Transaction Counts',
        url: '/reporting/transaction-counts',
        configPath: 'reporting.transactionCounts',
      },
      {
        label: 'Schedule Processing',
        url: '/reporting/schedule-processing',
        configPath: 'reporting.scheduleProcessing',
      },
      {
        label: 'Fare Checker',
        url: '/reporting/fare-checker',
        configPath: 'reporting.fareChecker',
      },
    ],
  },
  {
    label: 'iBank Reporting',
    icon: <TooltipIcon src={iBankReportingIcon} alt='iBank Reporting' />,
    configPath: 'iBank.active',
    items: [
      {
        label: 'Risk Messenger',
        url: '/risk-messenger',
        configPath: 'donotuse',
      },
      {
        label: 'Log in to iBank',
        url: '/iBank/login',
        configPath: 'iBank.active',
      },
    ],
  },
  {
    label: 'Event Driven Actions',
    icon: <TooltipIcon src={EDAIcon} alt='EDA' />,
    url: '/EDA',
    configPath: 'eda.active',
  },
  {
    label: 'Travel Authorization',
    icon: (
      <TooltipIcon src={TravelAuthorizationIcon} alt='Travel Authorization' />
    ),
    configPath: 'travelAuthorization.active',
    items: [
      {
        label: 'Active Requests',
        url: '/travel-authorization/active-requests',
        configPath: 'travelAuthorization.activeRequests',
      },
      {
        label: 'Search Requests',
        url: '/travel-authorization/search-requests',
        configPath: 'travelAuthorization.searchRequests',
      },
      {
        label: 'Travel Policy',
        url: '/travel-authorization/travel-policy',
        configPath: 'travelAuthorization.travelPolicy',
        adminRequired: true,
      },
      {
        label: 'Meetings',
        url: '/travel-authorization/meetings',
        configPath: 'travelAuthorization.meetings',
      },
      {
        label: 'EA Log',
        url: '/travel-authorization/logs',
        configPath: 'travelAuthorization.gtrLog',
      },
      {
        label: 'Document Log',
        url: '/travel-authorization/document-log',
        internalRequired: true,
      },
    ],
  },
  {
    label: 'Data Insights',
    icon: <TooltipIcon src={DataInsightsIcon} alt='Data Insights' />,
    configPath: 'dataInsights.active',
    items: [
      {
        label: 'iQCX Booked Travel',
        url: '/data-insights/iqcx-booked-travel',
        configPath: 'dataInsights.iqcxBookedTravel',
      },
      {
        label: 'Expense Data',
        url: '/data-insights/expense-data',
        configPath: 'dataInsights.expenseData',
      },
      {
        label: 'Program Management',
        url: '/data-insights/program-management',
        configPath: 'dataInsights.programManagement',
      },
      {
        label: 'Travel Performance',
        url: '/data-insights/travel-performance',
        configPath: 'dataInsights.travelPerformance',
      },
      {
        label: 'Schedule Changes',
        url: '/data-insights/schedule-changes',
        configPath: 'dataInsights.scheduleChanges',
      },
    ],
  },
  {
    label: 'iQCX Workflow',
    icon: <TooltipIcon src={WorkflowIcon} alt='Workflow' />,
    configPath: 'workflow.active',
    items: [
      {
        label: 'Schedules',
        url: '/workflow/schedules',
        configPath: 'workflow.schedules',
        adminRequired: true,
      },
      {
        label: 'Queue Monitor',
        url: '/workflow/queue-monitor',
        configPath: 'workflow.queueMonitor',
        adminRequired: true,
      },
      {
        label: 'Tables',
        url: '/workflow/tables',
        configPath: 'workflow.tables',
      },
      {
        label: 'Table Connector',
        url: '/workflow/table-connector',
        configPath: 'workflow.tableConnector',
      },
      {
        label: 'Libraries',
        url: '/workflow/libraries',
        configPath: 'workflow.libraries',
        adminRequired: true,
      },
      {
        label: 'TicketTRAK™',
        url: '/workflow/ticket-trak',
        configPath: 'workflow.ticketTrak',
      },
      {
        label: 'ResRules',
        url: '/workflow/resrules',
        configPath: 'workflow.resrules',
        internalRequired: true,
      },
    ],
  },
  {
    label: 'Administration',
    icon: <TooltipIcon src={AdministrationIcon} alt='Administration' />,
    adminRequired: true,
    items: [
      {
        label: 'Users',
        url: '/user',
        adminRequired: true,
      },
      {
        label: 'Site Configuration',
        url: '/site-configuration',
        adminRequired: true,
      },
      {
        label: 'Activity Log',
        url: '/activities',
        adminRequired: true,
      },
    ],
  },
  {
    label: 'CIS Internal',
    icon: <TooltipIcon src={ManageAccounts} alt='CIS Internal' />,
    configPath: 'cisInternal.active',
    internalRequired: true,
    internalPathRequired: true,
    items: [
      {
        label: 'Organizations',
        url: '/organizations',
        internalRequired: true,
      },
      {
        label: 'Schedule Mover',
        url: '/CIS-internal/schedule-mover',
        configPath: 'cisInternal.scheduleMover',
        internalRequired: true,
        internalPathRequired: true,
      },
      {
        label: 'Engine Monitor',
        url: '/CIS-internal/engine-monitor',
        configPath: 'cisInternal.engineMonitor',
        internalRequired: true,
        internalPathRequired: true,
      },
      {
        label: 'Queue Monitor',
        url: '/CIS-internal/queue-monitor',
        configPath: 'cisInternal.queueMonitor',
        internalRequired: true,
        internalPathRequired: true,
      },
      {
        label: 'iQCX Alert Notifications',
        url: '/CIS-internal/iqcx-alert-notifications',
        configPath: 'cisInternal.iQCXAlertNotifications',
        internalRequired: true,
        internalPathRequired: true,
      },
    ],
  },
];

export default navigationItems;
