import { ORG_TABLE_NAME } from '@/utils/aws/configs';
import { commandCall } from '@/utils/request';

export default async function getOrganization(orgName) {
  const response = await commandCall('GetCommand', {
    TableName: ORG_TABLE_NAME,
    Key: {
      PK: 'META',
      SK: `ORG:${orgName}`,
    },
  });

  return response.Item || {};
}
