import { ACTIVITY_LOG_TABLE_NAME } from '@/utils/constants';
import { commandCall } from '@/utils/request';

const allowedStatuses = {
  Error: true,
  Info: true,
  Change: true,
};

export default class Logger {
  constructor(module) {
    this.module = module;
  }

  setUser(user) {
    this.userEmail = user?.email || 'System';
    this.userName = user?.firstName
      ? `${user.firstName} ${user.lastName}`
      : 'System';
    this.userOrg = user?.organization || 'System';
  }

  // TODO: Research online makes it not clear whether we can safely perform a
  // synchronous write to DynamoDB from a Lambda.
  async log({ status, message, feature = '', ...additionalDataPoints }) {
    // Force use of only a finite amount of statuses.
    if (!allowedStatuses[status]) {
      throw new Error(`Improper status '${status}' supplied to log function!`);
    }

    // Force caller to set user before logging.
    if (!this.userOrg) {
      throw new Error(
        'No user supplied! You may need to invoke setUser prior to calling log!'
      );
    }

    const Item = {
      PK: this.userOrg,
      SK: new Date().toISOString(),
      module: this.module,
      feature,
      createdByEmail: this.userEmail,
      createdByName: this.userName,
      organization: this.userOrg,
      status,
      message,
      // Things like table name, iqcx account, etc...
      ...additionalDataPoints,
    };

    // Create a DynamoDB object.
    await commandCall('PutCommand', {
      TableName: ACTIVITY_LOG_TABLE_NAME,
      Item,
    });
  }
}
