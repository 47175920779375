import React, { useEffect } from 'react';
import { Snackbar, Alert, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SnackBarAlert = (props) => {
  const { openSnack, setOpenSnack, alert, snackPack, setAlert, setSnackPack } =
    props;

  useEffect(() => {
    if (snackPack.length && !alert) {
      // Set a new snack when we don't have an active one
      setAlert({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpenSnack(true);
    } else if (snackPack.length && alert && openSnack) {
      // Close an active snack when a new one is added
      setOpenSnack(false);
    }
  }, [snackPack, alert, openSnack]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleExited = () => {
    setAlert(undefined);
  };

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );
  return (
    <div>
      {' '}
      <Snackbar
        key={alert?.key || undefined}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        action={action}
      >
        <Alert
          key={alert?.key || undefined}
          onClose={handleClose}
          severity={alert?.severity}
        >
          <Typography>{alert?.message || ''}</Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBarAlert;
