import { useQuery } from 'react-query';
import axios from 'axios';

export default function useGet(endpoint, config) {
  // Handle query parameters. We perform this manually rather than letting axios do it
  // so that we can include the query params in the react-query key.
  let fullEndpoint = '/api' + endpoint;
  if (config?.params) {
    let queryParams = [];
    for (let param in config.params) {
      queryParams.push(param + '=' + encodeURIComponent(config.params[param]));
    }
    fullEndpoint = `${fullEndpoint}?${queryParams.join('&')}`;
  }

  const query = useQuery(fullEndpoint, () => axios.get(fullEndpoint), {
    enabled: config?.fireOnMount && !config.skip,
    refetchOnWindowFocus: false,
    refetchInterval: config?.refetchInterval,
    keepPreviousData: config?.keepPreviousData,
  });

  return {
    request: query,
    fire: query.refetch,
    isLoading: query.isLoading,
    isError: query.isError,
    data: query.data?.data?.body || query.data?.data || [],
    error: query.error?.response?.data?.body || query.error?.error,
    isFetching: query.isFetching,
  };
}
