import { colors, createTheme, alpha } from '@mui/material';
import { shadeColor } from '../utils/colors';

function createHubTheme(color) {
  return createTheme({
    spacing: (factor) => `${0.5 * factor}rem`,
    palette: {
      default: {
        main: colors.grey[500],
        lightest: colors.grey[100],
        dark: 'rgba(0, 0, 0, 0.87)',
      },
      primary: {
        main: color,
        dark: shadeColor(color, 110), //darken primary 10%
        light: shadeColor(color, 10),
        lighter: shadeColor(color, 5),
        alpha: {
          lightest: alpha(color, 0.05),
          lighter: alpha(color, 0.1),
        },
        cisBlue: '#040059',
        cisBlueDark: '#00447a',
        cisBlueLight: '#e9f4fd',
      },
      secondary: {
        main: '#ff8806',
        light: '#ffad32', //lighten secondary 20%
        lightest: '#FFF4E5',
      },
      tertiary: {
        main: '#4f88fc',
      },
      charcoal: {
        main: '#333333',
        light: '#666666',
      },
      success: {
        main: '#4aa148',
        lightest: '#EDF7ED',
      },
      error: {
        main: '#c91319',
      },
      background: {
        default: '#fff',
        card: '#e5eff7',
      },
      bgLightBlue: {
        default: '#e5eff7',
      },
      white: {
        main: '#fff',
      },
      grey: {
        main: colors.grey[700],
        lighter: colors.grey[500],
      },
      neutrals: {
        light: colors.grey[200],
        medium: colors.grey[500],
        dark: colors.grey[700],
        darkest: colors.grey[900],
      },
    },
    typography: {
      fontFamily: ['Raleway', 'Lato', 'Roboto', 'sans-serif'].join(','),
      h1: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.7rem',
        letterSpacing: '0.1',
      },
      h2: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.6rem',
        letterSpacing: '0.1',
      },
      h3: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.5rem',
        letterSpacing: '0.1',
      },
      h4: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.4rem',
        letterSpacing: '0.1',
      },
      h5: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.3rem',
        letterSpacing: '0.1',
      },
      h6: {
        fontWeight: 700,
        fontFamily: 'Sofia',
        fontSize: '1.2rem',
        letterSpacing: '0.1',
      },
      subtitle1: {
        fontSize: '1.1rem',
      },
      subtitle2: {
        fontWeight: 700,
        fontSize: '0.9rem',
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.9rem',
      },
      caption: {
        fontSize: '0.8rem',
        letterSpacing: '0.1rem',
      },
      overline: {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '0.8rem',
        letterSpacing: '0',
        lineHeight: 1.2,
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
  });
}

export default createHubTheme;
